<!--========================
Author by: Sreng Veasna
Created on: 26.09.2020
Modified:
Company: F2C V2
Description:
=========================-->
<template>
  <div>
    <v-card class="ma-4" flat>
      <TitleBar>
        Listeners
        <template #right-items>
          <PrimaryButton @click="$router.push('/listener/create')"
            >Add New</PrimaryButton
          >
        </template>
      </TitleBar>
      <SectionBar>
        <template #left-items>
          <SearchTextField v-model="search" />
          <!-- <v-switch class="pt-3" inset color="primary">
          <template #label>
            <div class="mt-2">User Listeners</div>
          </template>
        </v-switch> -->
        </template>
      </SectionBar>
      <v-data-table :headers="headers" :items="listeners" :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <!-- <v-icon small class="mr-4" @click="viewListener(item.idlistener)">
            mdi-format-list-bulleted
          </v-icon> -->
          <v-icon small class="mr-4" :disabled="!item.iduser" @click="editListener(item)">
            mdi-pencil
          </v-icon>
          <v-icon small :disabled="!item.iduser" @click="confirmDelete(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="400px" v-model="showHideModal">
      <v-card>
        <v-card-title>Are you sure you want to delete?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showHideModal = false">Discard</v-btn>
          <v-btn @click="deleteListener" color="warning">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "HomeListener",

  data: function () {
    return {
      search: "",
      showHideModal: false,
      listenerIdToDelete: null,
      listeners: [],
      headers: [
        { value: "displayname", text: "Name" },
        { value: "entity", text: "Entity" },
        { value: "queuename", text: "Queue Name" },
        {
          value: "actions",
          text: "Action",
          width: "100px",
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  mounted() {
    this.getListeners();
  },
  methods: {
    async getListeners() {
      this.$store.commit("toggle", "showHideModalSpinner");
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };
      try {
        const response = await this.$http.get("/api/listener", { headers });
        this.listeners = response.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
    viewListener(idlistener) {
      this.$router.push("/listener/view/" + idlistener);
    },
    editListener(item) {
      if(!item.iduser) return;
      this.$router.push("/listener/edit/" + item.idlistener);
    },
    confirmDelete(item) {
      if(!item.iduser) return;
      this.showHideModal = true;
      this.listenerIdToDelete = item.idlistener;
    },
    async deleteListener() {
      this.showHideModal = false;
      this.$store.commit("set", ["modalSpinnerText", "Deleting..."]);
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };
      try {
        await this.$http.delete("/api/listener/" + this.listenerIdToDelete, {
          headers,
        });
        this.getListeners();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
  },
};
</script>
